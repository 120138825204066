import { isNumber, PERCENTAGE, RATIO_UNIT } from '@netpurpose/types'
import { formatNumberWithThousandGroups, formatRatioAsPercent } from '@netpurpose/utils'

type FormatImpact = {
  value?: number | null | undefined
  unit?: string | undefined
  placeholder?: string
  precision?: number
}

export const formatImpact = ({ value, unit, placeholder, precision = 0 }: FormatImpact) => {
  return unit === RATIO_UNIT || unit === PERCENTAGE
    ? formatRatioAsPercent(value, placeholder)
    : formatNumberWithThousandGroups(value, precision, placeholder)
}

export const formatImpactPerMillion = (args: Omit<FormatImpact, 'precision'>) =>
  formatImpact({
    ...args,
    precision: 1,
  })

const getPrecision = (value: number): number => {
  if (value >= 10000 || value === 0) {
    return 0
  }
  return 2
}

export const formatImpactWithDynamicPrecision = (args: Omit<FormatImpact, 'precision'>) => {
  const precision = isNumber(args.value) ? getPrecision(args.value) : 0

  return formatImpact({
    ...args,
    precision,
  })
}
