import { Divider } from 'antd'
import { useRouter } from 'next/router'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { EstimatedData, useEntity, useEntityEstimatedData } from '@netpurpose/api'
import { useModalContext, useStore } from '@netpurpose/core'
import { Spinner } from '@netpurpose/np-ui'
import { TWELVE_HOURS_MS } from '@netpurpose/types'
import { formatDate } from '@netpurpose/utils'
import { EstimationLink } from '#components/company/CompanyData/CompanyData.style'
import {
  ButtonContainer,
  Card,
  DateContainer,
  FormulaContainer,
  OperationContainer,
  ReportValueButton,
} from '#components/company/CompanyData/KpiDerivation'
import { getFormattedValue } from '#components/company/CompanyData/KpiDerivation/FormulaComponents'
import { DataChallengeModal } from '#components/feedback/DataChallengeModal'
import { useAnalytics } from '#context/analytics'
import { AnalyticsEventName, AnalyticsLinkName } from '#types/analytics'

type EstimatedDataWithDatapointId = EstimatedData & { datapointId?: string }

type OnEstimationLinkClick = (vals: {
  technologyId: string
  datapointId: string | undefined
  hash?: string
}) => void

const Element = ({
  data,
  questionName,
  onEstimationLinkClick,
  hideQuestionName,
}: {
  data: EstimatedDataWithDatapointId
  questionName: string | undefined
  onEstimationLinkClick: OnEstimationLinkClick
  hideQuestionName: boolean
}) => {
  const { value, unit, technologyId, technologyName, reportingEndDate, datapointId, raw } = data
  const hash = encodeURIComponent(`${questionName}${reportingEndDate}${value}`)

  return (
    <Card>
      <EstimationLink
        className="follow-table-row-highlight"
        onClick={() => onEstimationLinkClick({ technologyId, hash, datapointId })}
        $raw={raw}
      >
        {getFormattedValue({ value, unit })}
      </EstimationLink>
      <div>{technologyName}</div>
      {!hideQuestionName && <div>{questionName}</div>}
      <DateContainer>{formatDate(new Date(reportingEndDate))}</DateContainer>
    </Card>
  )
}

export type EstimationDerivationProps = {
  entityId: number
  technologyIds: string[]
  questionId: number
  questionName: string
  year: number | undefined
  value: string | undefined
}

type Props = EstimationDerivationProps & {
  estimatedData?: EstimatedDataWithDatapointId[]
  setIsTooltipOpen: Dispatch<SetStateAction<boolean>>
  analyticsData: Record<string, unknown>
  activityName?: string
  hideQuestionName?: boolean
}

export const EstimationDerivation: FC<Props> = ({
  estimatedData: estimatedDataProp,
  entityId,
  technologyIds,
  year,
  value,
  questionId,
  questionName,
  setIsTooltipOpen,
  analyticsData,
  activityName,
  hideQuestionName = false,
}) => {
  const { openModal, isModalOpen, closeModal } = useModalContext()
  // Extra local state management for the specific modal we want is required so
  // that multiple modals for every EstimationDerivation component on a page
  // aren't all rendered at once.
  const [isSpecificModalOpen, setIsSpecificModalOpen] = useState(false)
  const analytics = useAnalytics()
  const router = useRouter()
  const isPortfolioPage = router.route.includes('portfolios')
  const { data: estimatedData, isFetching } = useEntityEstimatedData({
    entityId,
    questionId,
    year,
    enabled: !estimatedDataProp,
  })
  const data = estimatedDataProp || estimatedData
  const { data: entity } = useEntity({
    entityId,
    enabled: !!entityId && (!!isModalOpen.dataChallenge || !!isModalOpen.estimationDetail),
    staleTime: TWELVE_HOURS_MS,
  })

  const { setEstimationModalView } = useStore()

  const onEstimationLinkClick: OnEstimationLinkClick = ({ technologyId, datapointId, hash }) => {
    analytics?.logEvent(AnalyticsEventName.LinkClick, {
      link_name: AnalyticsLinkName.ViewEstimationsFromEstimationDerivationHover,
      technology_id: technologyId,
      ...(datapointId ? { datapoint_id: datapointId } : {}),
      ...analyticsData,
    })
    setIsTooltipOpen(false)
    if (datapointId) {
      router.replace(
        // For now, there will only be a datapoint_id if the user has hovered over a
        // revenue datapoint in the company SDGs tab.
        `${router.asPath}&datapoint_id=${datapointId}&selectedChildDatapointId=${datapointId}`,
      )
      openModal('datapointViewer')
    } else if (hash) {
      router.replace(
        `${router.asPath}&entityId=${entityId}&techIds=${encodeURIComponent(technologyIds.join('-'))}&active_modal_tab=${technologyId}#${hash}`,
      )
      setEstimationModalView('estimationDetail')
      openModal('estimationDetail')
    }
  }

  const onReportValueClick = () => {
    setIsTooltipOpen(false)
    setIsSpecificModalOpen(true)
    openModal('dataChallenge')
  }

  const handleDataChallengeModalClose = () => {
    closeModal('dataChallenge')
    setIsSpecificModalOpen(false)
  }

  if (isFetching && !data) {
    return <Spinner size="small" />
  }

  if (!data) {
    return null
  }

  const props = {
    questionName,
    onEstimationLinkClick,
    hideQuestionName,
  }

  const valueToReport = value !== undefined ? value : data.reduce((acc, cur) => acc + cur.value, 0)
  const yearToReport =
    year !== undefined ? year : parseInt(`${data[0]?.reportingEndDate}`.substring(0, 4))

  return (
    <>
      {data.length === 1 ? (
        <Element data={data[0] as EstimatedData} {...props} />
      ) : (
        <FormulaContainer>
          {data.map((child, index) => {
            const notLastEl = index !== data.length - 1
            return (
              <FormulaContainer key={`${child.technologyId}-${index}`}>
                <Element data={child} {...props} />
                {notLastEl && <OperationContainer>+</OperationContainer>}
              </FormulaContainer>
            )
          })}
        </FormulaContainer>
      )}
      <Divider style={{ margin: '0.5rem 0' }} />
      <ButtonContainer>
        <ReportValueButton onClick={onReportValueClick}>Report value</ReportValueButton>
      </ButtonContainer>
      {isModalOpen.dataChallenge && isSpecificModalOpen && (
        <DataChallengeModal
          isVisible={isModalOpen.dataChallenge && isSpecificModalOpen}
          onClose={handleDataChallengeModalClose}
          type={activityName ? 'activity' : isPortfolioPage ? 'portfolioKPI' : 'entityKPI'}
          id={questionId || ''}
          value={<span>{valueToReport}</span>}
          kpiName={activityName || questionName || ''}
          kpiType="Absolute"
          year={yearToReport}
          collectionName={entity?.name || ''}
        />
      )}
    </>
  )
}
