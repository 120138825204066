import { FC } from 'react'
import { TimeSeriesDatapoint, TimeSeriesFact } from '@netpurpose/api'
import { useLoggedIn } from '@netpurpose/core'
import { Icon } from '@netpurpose/np-ui'
import { isNumber, NOT_FOUND } from '@netpurpose/types'
import { Monospaced } from '#components/common/dataRendering'
import { DataChallengeDropdown } from '#components/feedback/DataChallengeDropdown'
import { formatImpact } from '#services/dataRendering/formatters'
import { canListEstimations } from '#utils/permissions'
import { Estimated, Reported, Standardised } from './FactValueComponents'

type Props = {
  datapoint?: TimeSeriesDatapoint
  entityName: string
  fact: TimeSeriesFact
  entityId: number
  isEstimated?: boolean
}

const NotFound: FC<{
  factId: number
  yearName: number | string
  questionName: string
  entityName: string
}> = ({ factId, yearName, questionName, entityName }) => (
  <DataChallengeDropdown
    type="fact"
    id={factId}
    value={<span>Not Disclosed</span>}
    year={yearName}
    questionName={questionName}
    entityName={entityName}
  >
    <Monospaced>{NOT_FOUND}</Monospaced>
  </DataChallengeDropdown>
)

export const FactValue: FC<Props> = ({ datapoint, entityId, entityName, fact }) => {
  const { user } = useLoggedIn()

  const { questionName, questionUnit, questionType, questionId } = fact

  const reported = datapoint?.reported
  const estimated = datapoint?.estimated

  const {
    value: reportedValue,
    factId,
    kpiResultId,
    year: reportedYear,
    isNotDisclosed: reportedIsNd,
    isPending,
  } = reported || {}
  const {
    value: estimatedValue,
    year: estimatedYear,
    isNotDisclosed: estimatedIsNd,
    technologyIds,
  } = estimated || {}

  // This scenario shouldn't be possible, so the 'Unknown' default is only to
  // keep TypeScript happy. There should in fact always be a reported year or
  // estimated year, and there's no reason they should be different anyway.
  const factOrEstimationYear = reportedYear || estimatedYear || 'Unknown'

  if (!factId && !estimated && !kpiResultId) {
    return (
      <NotFound
        factId={-1}
        yearName={factOrEstimationYear}
        entityName={entityName}
        questionName={questionName}
      />
    )
  }

  const reportedValueIsNotAvailable = !reported || reportedValue === NOT_FOUND || reportedIsNd
  const estimatedValueIsNotAvailable = !estimated || estimatedValue === NOT_FOUND || estimatedIsNd

  if (
    (reportedValueIsNotAvailable && !estimated) ||
    (reportedValueIsNotAvailable &&
      estimated &&
      (!canListEstimations(user) || estimatedValueIsNotAvailable))
  ) {
    return (
      <NotFound
        factId={reported?.factId || -1}
        yearName={factOrEstimationYear}
        entityName={entityName}
        questionName={questionName}
      />
    )
  }

  if (isPending && !estimatedYear) {
    return <Icon icon="Pending" alt="pending" color="typeTertiary" hideTooltip />
  }

  // If we've got this far but the reported value is not available, then there
  // must be an estimated value that we are able to display.
  const value = reportedValueIsNotAvailable || isPending ? estimatedValue : reportedValue
  const formattedValue = isNumber(value) ? formatImpact({ value, unit: questionUnit }) : value

  if (questionType === 'KPI') {
    return kpiResultId ? (
      <Standardised kpiResultId={kpiResultId} value={formattedValue} entityId={entityId} />
    ) : (
      <Monospaced>{formattedValue}</Monospaced>
    )
  }

  // By default, a valid reported value should always take precedence over an estimated
  // value in the instance that both exist.
  if (!reportedValueIsNotAvailable && factId && !isPending) {
    return (
      <Reported
        factId={factId}
        value={formattedValue}
        questionName={questionName}
        questionId={questionId}
        entityId={entityId}
        entityName={entityName}
        isLatestYear={false}
        // NOTE: passing year down to fetch correct year for timeseries estimated data
        {...(estimatedYear ? { year: estimatedYear } : {})}
      />
    )
  }

  if (estimatedYear && formattedValue) {
    return (
      <Estimated
        value={formattedValue}
        entityId={entityId}
        technologyIds={technologyIds || []}
        year={estimatedYear}
        questionId={questionId}
        questionName={questionName}
      />
    )
  }
  return <Monospaced>{formattedValue}</Monospaced>
}
