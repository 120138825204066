import { Divider } from 'antd'
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import { useEntity, useKpi, useKpiResult } from '@netpurpose/api'
import { Spinner } from '@netpurpose/np-ui'
import { TWELVE_HOURS_MS } from '@netpurpose/types'
import { DataChallengeModal } from '#components/feedback/DataChallengeModal'
import { useAnalytics } from '#context/analytics'
import { formatImpact } from '#services/dataRendering/formatters'
import { AnalyticsEventName, AnalyticsLinkName } from '#types/analytics'
import { BasicElement, BasicElementWithSegments, Formula } from './FormulaComponents'
import { ButtonContainer, Container, ReportValueButton } from './KpiDerivation.style'
import { getChosenCalculationPath } from './utils'

type Props = {
  kpiResultId: string
  setIsTooltipOpen: Dispatch<SetStateAction<boolean>>
  analyticsData: Record<string, string | number | undefined>
}

export const KpiDerivation: FC<Props> = ({ kpiResultId, setIsTooltipOpen, analyticsData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const analytics = useAnalytics()
  const { data: kpiResult, isFetching } = useKpiResult(kpiResultId, { staleTime: TWELVE_HOURS_MS })
  const { data: entity } = useEntity({
    entityId: kpiResult?.entityId,
    enabled: !!kpiResult?.entityId && !!isModalOpen,
    staleTime: TWELVE_HOURS_MS,
  })
  const { data: kpi } = useKpi(kpiResult?.kpiId, {
    enabled: !!kpiResult?.kpiId && !!isModalOpen,
    staleTime: TWELVE_HOURS_MS,
  })

  const chosenCalculationTree = useMemo(
    () => getChosenCalculationPath(kpiResult?.calculationTree),
    [kpiResult],
  )
  if (isFetching && !kpiResult) {
    return <Spinner size="small" />
  }
  if (!chosenCalculationTree) {
    return null
  }
  const onReportValueClick = () => {
    setIsTooltipOpen(false)
    setIsModalOpen(true)
  }
  const onFactClick = (factId: number | undefined) => {
    analytics?.logEvent(AnalyticsEventName.LinkClick, {
      link_name: AnalyticsLinkName.OpenFactViewerFromKpiResultHover,
      ...(factId ? { fact_id: factId } : {}),
      ...analyticsData,
    })
  }
  const withSegments = !!(
    chosenCalculationTree.children?.length && chosenCalculationTree.operation === 'add_segments'
  )
  const props = {
    calculationTree: chosenCalculationTree,
    onFactClick,
    kpiResultId,
  }
  return (
    <>
      <Container>
        {!chosenCalculationTree.children?.length ? (
          <BasicElement calculationTree={chosenCalculationTree} onFactClick={onFactClick} />
        ) : withSegments ? (
          <BasicElementWithSegments {...props} />
        ) : (
          <Formula {...props} parentOperation={chosenCalculationTree.operation} />
        )}
      </Container>
      <Divider style={{ margin: '0.5rem 0' }} />
      <ButtonContainer>
        <ReportValueButton onClick={onReportValueClick}>Report value</ReportValueButton>
      </ButtonContainer>
      {isModalOpen && (
        <DataChallengeModal
          isVisible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          type="entityKPI"
          id={kpiResultId}
          value={
            <span>
              {formatImpact({ value: kpiResult?.value.value, unit: kpiResult?.value.unit })}
            </span>
          }
          kpiName={kpi?.name || ''}
          kpiType="Absolute"
          {...(kpiResult?.year ? { year: kpiResult?.year } : {})}
          collectionName={entity?.name || ''}
        />
      )}
    </>
  )
}
