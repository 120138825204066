import { Dispatch, FC, MutableRefObject, SetStateAction } from 'react'
import { EntityImpactData, PortfolioImpactData } from '@netpurpose/api'
import { NavigationTable } from '@netpurpose/np-ui'
import { QuestionNameWithIcon } from '#components/company/CompanyData/CompanyData.style'
import { FunctionFormulaIconWithTooltip } from '#components/company/CompanyData/getColumns'
import { MetricNameWithInteraction } from '#components/MetricNameWithInteraction'
import { isEntityImpactData } from '../types'

export const getImpactLink =
  <TableData extends PortfolioImpactData | EntityImpactData>({
    onClick,
    isLastColumn = false,
  }: {
    onClick: (impactData: TableData) => void
    // prevents icon from being rendered in last column with > button
    isLastColumn?: boolean
  }): FC<{ record: TableData; children: React.ReactNode }> =>
  ({ record, children }) => {
    const factoidOrigin = isEntityImpactData(record) ? record.factoidOrigin : undefined

    return (
      <NavigationTable.LinkText
        onClick={() => onClick(record)}
        className="follow-table-row-highlight"
      >
        {factoidOrigin?.dataType === 'Standardised' && !isLastColumn ? (
          <QuestionNameWithIcon>
            {children}
            <FunctionFormulaIconWithTooltip tooltip={factoidOrigin.description || ''} />
          </QuestionNameWithIcon>
        ) : (
          children
        )}
      </NavigationTable.LinkText>
    )
  }

export const getImpactLinkWithMetricInteraction =
  <TableData extends { questionId: number | undefined; isContributing: boolean | undefined }>({
    onClick,
    metricCardRef,
    openMetricCardId,
    setOpenMetricCardId,
  }: {
    onClick: (impactData: TableData) => void
    metricCardRef: MutableRefObject<null>
    openMetricCardId: number | null
    setOpenMetricCardId: Dispatch<SetStateAction<number | null>>
  }): FC<{ record: TableData; children: React.ReactNode }> =>
  ({ record, children }) => (
    <MetricNameWithInteraction
      questionId={record.questionId}
      isContributing={!!record.isContributing}
      metricCardRef={metricCardRef}
      openMetricCardId={openMetricCardId}
      setOpenMetricCardId={setOpenMetricCardId}
    >
      <NavigationTable.LinkText
        onClick={() => onClick(record)}
        className="follow-table-row-highlight"
        // NOTE: to make only the text a link, not the whole cell
        style={{ display: 'inline-block' }}
      >
        {children}
      </NavigationTable.LinkText>
    </MetricNameWithInteraction>
  )
